import styled from '@emotion/styled';

export const MiniArticle = styled.div<{ white?: boolean }>`
    max-width: 522px;
    color: ${({ theme, white }) => (white ? theme.colors.gray1 : theme.colors.gray3)};

    > h2 {
        margin: 0 0 32px;
        color: ${({ theme, white }) => (white ? theme.colors.gray1 : theme.colors.green2)};
    }

    > h3 {
        margin: 0 0 24px;
    }

    > p {
        margin: 0;
    }

    > h6 {
        margin: 32px 0 16px;
    }

    > a {
        margin-top: 32px;
    }

    > ul {
        margin: 0 0 32px;

        li:not(:last-of-type) {
            margin-bottom: 16px;
        }

        & + a {
            margin-top: 0;
        }
    }

    @media (min-width: 1280px) {
        > h2 {
            margin-bottom: 64px;
        }

        > h3 {
            margin-bottom: 32px;
        }

        > h6 {
            margin: 40px 0 32px;
        }

        > a {
            margin-top: 64px;
        }

        > ul {
            margin-bottom: 64px;

            li:not(:last-of-type) {
                margin-bottom: 24px;
            }

            & + a {
                margin-top: 0;
            }
        }
    }
`;
